@charset "utf-8";
/* ============================================================ */
/* voice */
/* ============================================================ */
.voice {
	.voice-item {
		.ttl05 {
			position: relative;
			padding-right: 100px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding-right: 80px; }
		}
		&__date {
			@include centering-elements(false, true);
			right: 0;
			color: #666666;
			font-size: 1.6rem;
		}	
	}
}
