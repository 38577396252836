@charset "utf-8";
/* ============================================================ */
/* about */
/* ============================================================ */
.about {
	.catch01 {
		margin-bottom: 30px;
	}
	.box--brackets {
		border: none;
		text-align: center;
		padding: 30px;
		margin-bottom: 0;
		
		&::before,&::after {
			display: none;
		}
		&__inner {
			&::before,&::after {
				display: none;
			}
		}
	}
	.nubmer01 {
		text-align: center;
		font-size: 4.5rem;
		@include noto-serif;
		line-height: 1.3;
		color: $baseColor;
		margin-bottom: 0;
		font-style: italic;
	}
	.tkc {
		margin-bottom: 10px;
		&__item {
			border: 1px solid #e3e3e3;
			padding: 25px 20px;
			font-size: 1.6rem;
			@include noto-serif;
			position: relative;
			text-align: left;
			text-decoration: none;
			color: $textColor;
			@media screen and (max-width:1024px) { padding: 15px 10px; }
			.__ico {
				@include centering-elements(false, true);
				right: 15px;
				font-size: 1.6rem;
				@media screen and (max-width:1024px) { right: 5px;font-size: 1.4rem; }
			}
		}
	}
	.tkc-bnr {
		&__item {
			border: 1px solid #e3e3e3;
		}
	}
}
