@charset "utf-8";
/* ============================================================ */
/* voice */
/* ============================================================ */
.voice {
	.voice-item {
		&__date {
			text-align: right;
			font-size: 1.4rem;
			color: #666666;
			display: block;
		}	
	}
}