@charset "utf-8";
/* ============================================================ */
/* recruit */
/* ============================================================ */
.recruit {
	@media screen and (min-width:  767.5px) and (max-width:1024px) {
		.lnav--column4 > *{
			width: calc((100% - 21px)/ 2);
		}
	}
	.ttl02 {
		font-size: 3.4rem;
		transform: rotate(0.05deg); 
		&::after {
			border-bottom: 1px solid $borderColor;
			@include centering-elements(true, false);
			content: '';
			bottom: 0;
			width: 80px;
		}
	}
	.timeline {
		&__item {
			overflow: hidden;
			margin: 0;
			position: relative;
			display: flex;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { display: block;}
			& > * {
				padding-bottom: 45px;
			}
			
			&:last-child > * {
				padding-bottom: 0;
			}

			&__body {
				border-left: 3px solid $baseColorLight;
				padding: 0 0 0 20px;
				margin-left: 5px;
				&::before {
					content: '';
					width: 12px;
					height: 12px;
					background: #609dbf;
					position: absolute;
					top: 10px;
					left: 0;
					border-radius: 100%;
				}
				&__title {
					line-height: 1.0;
					@include noto-serif;
					font-size: 2.6rem;
					display: flex;
					&__date {
						line-height: 1.0;
						@include noto-serif;
						color: #609dbf;
						font-size: 2.6rem;
						min-width: 90px;
					}
				}
				
				&__text {
					margin-left: 90px;
					padding-bottom: 45px;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { padding-bottom: 15px; }
				}
			}
			&__img {
				min-width: 320px;
				margin-left: 30px;
				padding-bottom: 45px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) {
					margin-left: 5px;
					padding-left: 110px;
					border-left: 3px solid $baseColorLight;
				}
			}
		}
	}
	.btn--md {
		min-width: 400px;
	}
}
