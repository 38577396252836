@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	height: 100%;
	margin: 0;
	overflow-x: hidden;
	
	.wrap {
		background: linear-gradient(-45deg, rgba(223, 239, 247, 0.1), rgba(223, 239, 247, 0.1));
	}
	
	.visual {
		text-align: center;
		justify-content: center;
		display: flex;
		overflow: hidden;
		height: 100vh;
		position: relative;
		&__movie {
			width: auto;
			height: 100vh;
			min-height: 100%;
			z-index: -100;
		}
		&__logo {
			position: absolute;
			z-index: 2;
			@include centering-elements(true, true);
			width: 85%;

			.index-title {
				color: $white;
				font-size: 1.9rem;
				@include noto-serif;
				display: block;
				letter-spacing: -0.05em;
				text-shadow: 0px 0px 11px #444444;
				
				&--01 {
					font-size: 3.0rem;
				}
			}
		}
		&__scroll {
			color: #f0f0f0;
			text-decoration: none;
			@include centering-elements(true, false);
			bottom: 0;
			transition: 0.3s all;
			padding-bottom: 45px;
			z-index: 3;
			&::after {
				border-right: 1px solid #f0f0f0;
				content: "";
				height: 40px;
				bottom: 0;
				@include centering-elements(true, false);
			}
		}
	}
	
	.contents {
		margin: 0;
		.ttl02 {
			margin-bottom: 20px;
			&::after {
				border-bottom: 1px solid $borderColor;
				content: "";
				width: 40px;
				bottom: 0;
				@include centering-elements(true, false);
			}
			&__icon {
				max-width: 25px;
				display: block;
				margin: 0 auto 10px;
			}
		}
	}
	.index-news {
		padding: 30px 20px;
		&__title {
			border-bottom: 1px solid #e4e4e4;
			display: table;
			padding-right: 10px;
			@include noto-serif;
			color: $baseColor;
			font-size: 1.8rem;
			margin-bottom: 10px;
		}
		&__date {
			color: #979797;
		}
		&__text a {
			color: $textColor;
		}
	}
	.index-about,
	.index-recruit {
		position: relative;
		padding-bottom: 40px;
		&__box {
			background: rgba(255,255,255,0.9);
			padding: 40px 30px;
			margin-top: -70px;
			margin-left: 40px;
			position: relative;
			z-index: 2;
		}
	}
	
	.index-about {
		.ttl02 {
			display: flex;
			align-items: center;
			&__icon {
				max-width: 30px;
				margin: 0 10px;
			}
			&::after {
				transform: translate(0,0);
				left: 0;
				width: 50px;
			}
		}
	}
	.index-works {
		position: relative;
		margin-bottom: 40px;
		&__box {
			position: absolute;
			@include centering-elements(true, true);
			width: 80%;
			
			.ttl02 {
				color: #edf6f7;
				&__icon {
					max-width: 50px;
				}
			}
		}
	}
	.index-staff,
	.index-voice,
	.index-office {
		display: flex;
		margin-bottom: 20px;
		text-decoration: none;
		color: $textColor;
		& > * {
			width: 50%;
		}
		&__text {
			background: $white;
			display: flex;
			justify-content: center;
			align-items: center;
			.ttl02 {
				margin: 0;
				padding-bottom: 0;
				&::after {
					border-bottom: none;
				}
				&__text {
					&::after {
						color: $borderColor;
					}
				}
			}
		}
	}
	.index-recruit {
		&__box {
			margin-left: 30px;
			margin-right: 30px;
			.ttl02 {
				&__icon {
					max-width: 45px;
				}
			}
		}
	}
}