@charset "utf-8";
/* ============================================================ */
/* works */
/* ============================================================ */
.works {
	.service-block {
		position: relative;
		padding-top: 20px;
		margin-bottom: 70px;

		&__img {
			position: absolute;
			left: 0;
			top: 20px;
			background: #d0e2ea;
			display: flex;
			justify-content: center;
			align-items: center;
			max-width: 32%;
			height: calc(100% - 40px);
			@media screen and (min-width:  767.5px) and (max-width:1024px) { max-width: 24%; }
			&__number {
				width: 105px;
				position: absolute;
				z-index: 2;
				top: 0;
				left: 20px;
				margin-top: -30px;
			}
		}
		&__text {
			background: $white;
			padding: 45px 25px 45px 45px;
			max-width: 70%;
			margin: 20px 0 0 auto;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { 
				max-width: 78%;
				padding: 30px 20px 30px 40px;
				.ttl04 {
					font-size: $xxxl;
				}
			}
		}
	}
}
