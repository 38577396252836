@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.footer-contact {
	position: relative;
	padding-bottom: 60px;
	&__box {
		background: rgba(255,255,255,0.9);
		padding: 40px 30px;
		margin-top: -70px;
		margin-left: 40px;
		position: relative;
		z-index: 2;
		&__btn {
			background: $textColor;
		}
	}
	.ttl02 {
		display: flex;
		align-items: center;
		&::after {
			border-bottom: 1px solid $borderColor;
			content: "";
			width: 40px;
			bottom: 0;
			@include centering-elements(true, false);
		}
		&__icon {
			max-width: 30px;
			margin: 0 10px;
			&:before {
				color: $textColor;
			}
		}
		&::after {
			transform: translate(0,0);
			left: 0;
			width: 50px;
		}
	}
}

/* .fnav
----------------------------------------------------------- */
.fnav {
	background: $white;

	&__column__ttl {
		font-size: 1.3rem;
		border-top: 1px solid $borderColor;
		position: relative;

		&__link {
			display: block;
			text-decoration: none;
			color: $textColor;
			padding: 12px 15px;
		}
	}
	.icon-angle-down,
	.icon-angle-right {
		content: " ";
		@include centering-elements(false, true);
		right: 20px;
	}
}

footer {
	.footer-content {
		padding: 35px 0;
		background: $textColor;
		color: #b1b1b1;
		&__logo {
			display: block;
			width: 250px;
			height: auto;
			margin: 0 auto 20px;
		}
		&__address {
			text-align: center;
			margin: 0 0 25px;
			
			a {
				color: #b1b1b1;
				text-decoration: none;
			}
		}
	}

	.copyright {
		text-align: center;
		border-top: 1px solid #4d4d4d;
		color: #b1b1b1;
		padding: 20px 0;
		background: $textColor;
		font-size: $xxs;
	}
}