@charset "utf-8";
/* ============================================================ */
/* about */
/* ============================================================ */
.about {
	.catch01 {
		font-size: 3.7rem;
		margin-bottom: 70px;
		@media screen and (max-width:1024px) { font-size: 4.8rem;margin-bottom: 40px; }
	}
	.ttl02 {
		font-size: 3.4rem;
	}
	.nubmer01 {
		text-align: center;
		font-size: 4.5rem;
		@include noto-serif;
		color: $baseColor;
		margin-bottom: 0;
		line-height: 1.3;
		font-style: italic;
	}
	.tkc {
		margin-bottom: 30px;
		&__item {
			border: 1px solid #e3e3e3;
			padding: 25px 20px;
			font-size: 1.6rem;
			@include noto-serif;
			position: relative;
			text-align: left;
			text-decoration: none;
			color: $textColor;
			@media screen and (max-width:1024px) { padding: 15px 10px; }
			.__ico {
				@include centering-elements(false, true);
				right: 15px;
				font-size: 1.6rem;
				@media screen and (max-width:1024px) { right: 5px;font-size: 1.4rem; }
			}
			&:hover {
				background: #f6f7f8;
			}
		}
	}
	.tkc-bnr {
		margin-left: -6px;
		margin-right: -6px;
		&__item {
			margin-left: 6px;
			margin-right: 6px;
			width: calc((100% - 28px) / 2 );
			border: 1px solid #e3e3e3;
			&:hover {
				opacity: 0.7;
			}
		}
	}
}
