@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	.row {
		padding: 40px 15px 60px;
	}
}

#loader-bg {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100% !important;
	z-index: 110;
	background: $white;

	#loader {
		@include centering-elements(true, true);
		display: none;
		position: fixed;
		width: 75px;
		height: 80px !important;
		text-align: center;
		z-index: 15;

		svg {
			position: relative;
			top: 1px;
			width: 100%;
		}
	}
}

.link_scroll {
	margin-top: -50px!important;
	padding-top: 50px!important;
}

/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

.bg-gray {
	background: #edf6f7;
}
.bg-white {
	background: $white;
}
.bg-blue {
	background: $baseColorLight;
}

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01__bg {
	margin-top: 50px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	height: 150px;
	background-size: cover;
	font-weight: bold;
	line-height: 1.2;
	position: relative;
	background-position: bottom;
	background-repeat: no-repeat;
	
	.news & { background-image: url(/common/img/news/bg_ttl01.png); }
	.works & { background-image: url(/common/img/works/bg_ttl01.png); }
	.staff & { background-image: url(/common/img/staff/bg_ttl01.png); }
	.voice & { background-image: url(/common/img/voice/bg_ttl01.png); }
	.office & { background-image: url(/common/img/office/bg_ttl01.png); }
	.recruit & { background-image: url(/common/img/recruit/bg_ttl01.png); }
	.contact & { background-image: url(/common/img/contact/bg_ttl01.png); }
	.about & { background-image: url(/common/img/about/bg_ttl01.png); }
	.privacy &,
	.error & { background-image: url(/common/img/common/bg_ttl01.png); }
	.ttl01 {
		display: flex;
		align-items: center;
		animation-name: title-fade;
		animation-duration: 2.5s;
		&__text {
			font-size: 3.2rem;
			color: $white;
			@include noto-serif;
			&::after {
				text-align: left;
				line-height: 1.5;
				display: block;
				content: attr(data-text);
				font-family: 'Noto Sans Japanese', sans-serif;
				font-size: 1.3rem;
				font-weight: $font-demilight;
			}
		}
	}
}

.ttl02 {
	text-align: center;
	font-size: 2.6rem;
	font-weight: bold;
	@include noto-serif;
	line-height: 1.3;
	position: relative;
	padding-bottom: 20px;
	&__text {
		&::after {
			content: attr(data-text);
			font-family: 'Noto Sans Japanese', sans-serif;
			color: #969696;
			font-size: $xs;
			font-weight: $font-demilight;
			display: block;
		}
	}
}

.ttl03 {
	margin: 0 0 50px;
	font-size: 2.8rem;
	text-align: center;
	line-height: 1.4;
	@include noto-serif;
	padding-bottom: 10px;
	position: relative;
	&::after {
		border-bottom: 1px solid #929292;
		@include centering-elements(true, false);
		content: '';
		bottom: 0;
		width: 80px;
	}
}

.ttl04 {
	position: relative;
	margin: 0 0 10px;
	font-size: 2.2rem;
	font-weight: bold;
	@include noto-serif;
	padding-left: 30px;
	&::before {
		border-top: 1px solid #929292;
		position: absolute;
		top: 20px;
		content: '';
		left: 0;
		width: 25px;
	}
}

.ttl05 {
	position: relative;
	margin: 0 0 20px;
	padding: 0 0 10px;
	border-bottom: 1px solid $borderColor;
	font-size: $l;
	line-height: 1.4;
	
	&::after {
		position: absolute;
		left: 0;
		bottom: -1px;
		content: '';
		width: 115px;
		border-bottom: 1px solid $baseColor;
	}
}

.ttl06 {
	margin: 0 0 10px;
	padding-bottom: 5px;
	border-bottom: 1px solid #929292;
	font-size: $m;
	line-height: 1.4;
}

.catch01 {
	@include noto-serif;
	text-align: center;
	font-size: 2.1rem;
	margin-bottom: 15px;
	color: $baseColor;
	transform: rotate(0.05deg);
	letter-spacing: -0.05em;
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}

	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--orange {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;
	background: #fff;

	&--gray {
		background: #f9f9f9;
	}
	&--border-gray {
		border: 1px solid $borderColor;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
	&--brackets {
		border: 1px solid $baseColorLight;
		position: relative;
		margin-bottom: 30px;
		padding: 0;
		
		&::before,
		&::after,
		&__inner::before,
		&__inner::after {
			content:'';
			width: 30px;
			height: 30px;
			position: absolute;
			display: inline-block;
		}

		&::before {
			border-left: solid 1px $baseColor;
			border-bottom: solid 1px $baseColor;
			bottom: -1px;
			left: -1px;
		}

		&::after{
			border-right: solid 1px $baseColor;
			border-bottom: solid 1px $baseColor;
			bottom: -1px;
			right: -1px;
		}
		
		&__inner{
			padding: 20px;
			position: relative;
			&::before {
				border-left: solid 1px $baseColor;
				border-top: solid 1px $baseColor;
				top: -1px;
				left: -1px;
			}

			&::after{
				border-right: solid 1px $baseColor;
				border-top: solid 1px $baseColor;
				top: -1px;
				right: -1px;
			}
		}
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	table-layout: fixed;
	font-size: $xs;
	line-height: 1.6;
	word-break: break-all;
	word-wrap: break-word;

	th,
	td {
		padding: 10px;
		border-bottom: 1px solid $borderColor;
		border-top: 1px solid $borderColor;
		background: $white;
		text-align: left;
		vertical-align: middle;
	}
	.bg-gray {
		background: #f3f3f3;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
	
	.bg-bluelight {
		background: $baseColorLight;
		border-top: 1px solid $baseColorLight;
		border-bottom: 1px solid $white!important;
	}
	
	&--sp-column1 {
		display: block;
		
		thead,
		tbody {
			display: block;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
		.bg-bluelight {
			border-top: 1px solid $borderColor!important;
			border-bottom: none!important;
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list {
		tr {
			&:nth-child(odd) {
				th,td {
					background: $baseColorLight;
				}
			}
		}
		th,
		td {
			border: none;
			background: $white;
			padding: 10px 0 5px 10px;
		}
	}
	
}

/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 70%;
	height: 50px;
	margin: 5px 0;
	padding: 0 30px;
	color: #fff;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	
	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 10px; }
	.icon-fix-right { right: 10px; }
	
	&--blue {
		background: $baseColor;
	}
	&--black {
		background: $textColor;
	}
	&--lg {
		width: 100%;
		height: 50px;
	}
	&--md {
		width: 90%;
		height: 55px;
	}
	&--sm {
		display: inline-flex;
		min-width: 0;
		min-height: 35px;
		padding: 0 20px;
		font-size: $xxs;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 10px;

		& > * {
			display: block;
			padding: 5px 12px;
			background: none;
			border: 1px solid $baseColor;
			color: $baseColor;
			text-decoration: none;
			@include radius(3);
			
			&.current {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: $white;
				opacity: 1;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion,
.sp-accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColor;
		font-size: $xxs;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 0;
	padding: 2px 10px;
	background: $baseColor;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"],
textarea,
select {
	color: $textColor;
	font-size: 16px;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	width: 100px;
	height: 25px;
	border: 1px solid #808080;
	color: #808080;
	font-size: $s;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
}

/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin: 0 0 40px;
	&__item {
		display: flex;
		flex-wrap: wrap;
		padding: 15px 0;
		border-bottom: 1px solid #e4e4e4;
		&__date {
			width: 100%;
			color: #959595;
			font-size: 1.4rem;
		}
		img {
			max-width: 100%;
			height: auto;
		}
	}
}

/* --------------------------------------------------- */
/* lnav */
/* --------------------------------------------------- */
.lnav {
	display: flex;
	flex-wrap: wrap;
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: 40px;
	
	&--column3 {
		& > * {
			width: calc((100% - 31px) / 3);
		}
	}
	&--column4 {
		& > * {
			width: calc((100% - 41px) / 4);
			.icon-fix-right {
				right: 5px;
			}
		}
	}
	&--sp-column2 {
		& > * {
			width: calc((100% - 21px) / 2);
			.icon-fix-right {
				right: 5px;
			}
		}
	}
	&__item {
		margin-left: 5px;
		margin-right: 5px;
		background: #8bb6d3;
		cursor: pointer;
		display: block;
		padding: 0;
		
		&__text {
			font-size: $xs;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}
	}
}
