@charset "utf-8";
/* ============================================================ */
/* works */
/* ============================================================ */
.works {
	.service-block {
		padding-top: 20px;
		margin-bottom: 50px;
		margin-top: 20px;

		&__img {
			background: #d0e2ea;
			display: flex;
			justify-content: center;
			align-items: center;
			max-width: 100%;
			position: relative;
			padding: 25px 0 20px 0;
			&__number {
				width: 70px;
				position: absolute;
				z-index: 2;
				top: 0;
				left: 20px;
				margin-top: -35px;
			}
		}
		&__text {
			background: $white;
			padding: 20px;
			max-width: 100%;
		}
	}
}
