@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	position: relative;
	width: 100%;
	background: $baseColorLight;
	.footer-contact {
		padding: 70px 0 45px 0;
		margin-right: 120px;
		text-align: center;
		@media screen and (min-width:  767.5px) and (max-width:1024px) {
			margin-right: 90px;
		}
		&__pagetop {
			position: absolute;
			right: 170px;
			top: 0;
			margin-top: -40px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { right: 120px;margin-top: -33px; }
			&__link {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				width: 80px;
				height: 80px;
				background: $white;
				color: $baseColor;
				font-size: 3.3rem;
				text-decoration: none;
				@include radius(40);
				box-shadow: 0px 0px 7px 0px $baseColor;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 66px;height: 66px;font-size: 2.8rem; }
				.icon-angle-up {
					margin-top: -10px;
				}
				&:hover {
					opacity: 0.7;
				}
			}
		}
		&__box {
			&__icon {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 50px;
				height: 50px;
				background: $textColor;
				color: $white;
				font-size: $xxxl;
				text-decoration: none;
				@include radius(25);
				position: absolute;
				top: 0;
				@include centering-elements(true, false);
				margin-top: -25px;
				margin-left: -60px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) {
					margin-left: -45px;
				}
				.icon-page-top {
					padding-right: 4px;
					padding-top: 2px;
				}
			}
			&__tel {
				background: none;
				font-size: 3.9rem!important;
				padding: 0 10px;
				.icon-phone {
					color: $baseColor;
					font-size: 3.4rem;
				}
				.icon-angle-right {
					display: none;
				}
			}
			&__btn {
				background: $baseColor;
			}
			@media screen and (min-width:  767.5px) and (max-width:1024px) { 
				.btn--md { 
					min-width: 280px;
				}
			}
		}
	}
	.footer-info {
		text-align: center;
		bottom: 0;
		left: 30px;
		width: 100%;
		height: 55px;
		.footer-info__body {
			padding: 0 150px 0 0;
			height: 55px;
			width: 100%;
			color: #cacaca;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 0 100px 0 0;}
			.copyright {
				white-space: nowrap;
				color: #989898;
				font-size: $xxs;
				padding-bottom: 10px;
			}
		}
	}
}