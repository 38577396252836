@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	position: fixed;
	width: 100%;
	background: $white;
	height: 50px;
	right: 0;
	top: 0;
	z-index: 5;

	.headerwrap {
		overflow: hidden;
		display: flex;
		justify-content: flex-end;
		height: 50px;
		align-items: center;
		&__logo {
			@include centering-elements(false,true);
			left: 5px;
			width: 200px;
		}
		&__icon {
			text-decoration: none;
			@include centering-elements(false,true);
			right: 60px;

			.icon-page-top:before {
				color: #000;
				font-size: 2.5rem;
			}
		}
	}
	
	.menuopen {
		position: relative;
		width: 30px;
		height: 26px;
		right: 10px;
		top: 0;
		z-index: 20;
		cursor: pointer;
		@include transition;
		font-size: 1.3rem;
		text-decoration: none;
		text-align: center;
		outline: none;

		&__item {
			display: inline-block;
			position: absolute;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: $textColor;
			@include radius(2);
			@include transition;
			
			&:nth-of-type(1) {
				top: 0;
				animation: menu-bar01sp .75s forwards;
			}
			&:nth-of-type(2) {
				top: 12px;
				opacity: 1;
			}
			&:nth-of-type(3) {
				bottom: 0;
				animation: menu-bar02sp .75s forwards;
			}
		}
	}
	.menuopen.active {
		.menuopen__item {
			&:nth-of-type(1) {
				top: 5px;
				animation: active-menu-bar01sp .75s forwards;
			}
			&:nth-of-type(2) {
				opacity: 0;
			}
			&:nth-of-type(3) {
				bottom: 4px;
				animation: active-menu-bar03sp .75s forwards;
			}
		}
	}
	.gnav {
		width: 100%;
		height: auto;
		min-height: 100vh;
		position: fixed;
		top: 0;
		background: rgba(53,143,193,.95);
		color: #fff;
		pointer-events: none;
		transition: 0.5s ease;
		z-index: 15;
		counter-reset: nav;
		overflow-y: auto;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		right: -100%;
		
		&__item {
			counter-increment: nav;
			transition: 0.8s ease;
			position: relative;
			right: -440px;
			
			&__link {
				display: block;
				padding: 7px 5px;
				text-align: center;
				cursor: pointer;
				transition: all 0.5s ease;
				color: $white;
				font-size: 1.2rem;
				line-height: 1.2;
				text-decoration: none;
				@include noto-serif;
				&__en {
					@include noto-serif;
					display:block;
					color: #c6dbe7;
					font-size: 2.0rem;
				}
			}
			&--sub {
				margin-bottom: 5px;		
				.gnav__item__link {
					font-weight: bold;
					color: #c6dbe7;
				}
			}
		}
		&__tel {
			border-top: 1px solid $white;
			margin-right: 28px;
			margin-left: 40px;
			margin-bottom: 5px;
			font-size: $xxxl;
			padding-top: 10px;
			font-weight: bold;
			display: inline-block;
			counter-increment: nav;
			transition: 0.8s ease;
			position: relative;
			right: -440px;
			color: $white;
			text-decoration: none;
		}
	}
	.menuopen.active + .gnav {
		right: 0;
		width: 100%;
		pointer-events: visible;
		overflow: hidden;
		
		.gnav__item {
			right: 0;
			&:nth-of-type(2) { transition-delay: 0.1s;}
			&:nth-of-type(3) { transition-delay: 0.15s;}
			&:nth-of-type(4) { transition-delay: 0.2s;}
			&:nth-of-type(5) { transition-delay: 0.25s;}
			&:nth-of-type(6) { transition-delay: 0.3s;}
			&:nth-of-type(7) { transition-delay: 0.35s;}
			&:nth-of-type(8) { transition-delay: 0.4s;}
			&:nth-of-type(9) { transition-delay: 0.45s;}
			&:nth-of-type(10) { transition-delay: 0.5s;}
		}
		.gnav__tel {
			right: 0;
			transition-delay: 0.55s;
		}
	}
}