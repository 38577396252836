@charset "utf-8";
/* ============================================================ */
/* iconフォント */
/* ============================================================ */
@font-face {
  font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot?kmdreq');
  src:  url('/common/icon/fonts/icomoon.eot?kmdreq#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2?kmdreq') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf?kmdreq') format('truetype'),
    url('/common/icon/fonts/icomoon.woff?kmdreq') format('woff'),
    url('/common/icon/fonts/icomoon.svg?kmdreq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e903";
}
.icon-page-top:before {
  content: "\e900";
  color: #fff;
}
.icon-menu:before {
  content: "\e901";
}
.icon-news:before {
  content: "\e902";
  color: #009ae3;
}
.icon-arrow-left:before {
  content: "\f060";
}
.icon-arrow-right:before {
  content: "\f061";
}
.icon-external-link:before {
  content: "\f08e";
}
.icon-phone:before {
  content: "\f095";
}
.icon-list-ul:before {
  content: "\f0ca";
}
.icon-angle-double-left:before {
  content: "\f100";
}
.icon-angle-double-right:before {
  content: "\f101";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
