@charset "utf-8";
/* ============================================================ */
/* office */
/* ============================================================ */
.office {
	.message-bnr {
		background: url(/common/img/office/pic_office_01.png) no-repeat;
		background-size: cover;
		height: 350px;
		position: relative;
		margin-bottom: 60px;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { margin-bottom: 150px;}
		&__title {
			position: absolute;
			right: 30px;
			@include centering-elements(false, true);
			font-size: 4.0rem;
			@include noto-serif;
			color: $baseColor;
			text-align: right;
			line-height: 1.4;
			letter-spacing: -0.05em;
			@media screen and (min-width:  767.5px) and (max-width:1024px) {
				margin-top: 240px;
				left: 0;
				text-align: left;
				font-size: 3.6rem;
			}
		}
		&__name {
			position: absolute;
			bottom: 30px;
			right: 30px;
			text-align: right;
			font-size: 3.3rem;
			@include noto-serif;
			line-height: 1.3;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { margin-top: 400px; bottom:auto;}
		}
	}
	#googlemap {
		height: 420px;
	}
}
