@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
/* #loader-bg
----------------------------------------------------------- */
#loader-bg {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100% !important;
	z-index: 110;
	background: $white;

	#loader {
		@include centering-elements(true, true);
		display: none;
		position: fixed;
		width: 75px;
		height: 80px !important;
		text-align: center;
		z-index: 15;

		svg {
			position: relative;
			top: 1px;
			width: 100%;
		}
	}
}

.contents {
	margin-right: 120px;
	@media screen and (min-width:  767.5px) and (max-width:1024px) {
		margin-right: 90px;
	}
	.row {
		max-width: 1040px;
		margin: 0 auto;
		padding: 30px 20px 80px;
		&--narrow {
			padding: 0 50px 80px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 0 20px 80px; }
		}
	}
	
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */
.bg-white {
	background: $white;
}

.bg-blue {
	background: $baseColorLight;
}


/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}

a[href^="tel:"] {
	cursor: default;
	color: $textColor;
	text-decoration: none;
	&:hover {
		opacity: 1.0;
	}
}

/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01__bg {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 0 15px;
	background-position: bottom left;
	background-repeat: no-repeat;
	background-size: cover;
	line-height: 1.2;
	overflow:hidden;
	height: 452px;

	@media screen and (max-width:1024px) { height: 45vh; }

	.news & { background-image: url(/common/img/news/bg_ttl01.png); }
	.works & { background-image: url(/common/img/works/bg_ttl01.png); }
	.staff & { background-image: url(/common/img/staff/bg_ttl01.png); }
	.voice & { background-image: url(/common/img/voice/bg_ttl01.png); }
	.office & { background-image: url(/common/img/office/bg_ttl01.png); }
	.recruit & { background-image: url(/common/img/recruit/bg_ttl01.png); }
	.contact & { background-image: url(/common/img/contact/bg_ttl01.png); }
	.about & { background-image: url(/common/img/about/bg_ttl01.png); }
	.privacy &,
	.error &
	 { background-image: url(/common/img/common/bg_ttl01.png); }
	
	.ttl01 {
		display: flex;
		align-items: center;
		margin-right: 120px;
		animation-name: title-fade;
		animation-duration: 2.5s;
		@include noto-serif;
		@media screen and (max-width:1024px) { margin-right: 90px; }
		&__text {
			font-size: 4.6rem;
			color: $white;
			@include noto-serif;
			text-align: center;
			&::after {
				line-height: 1.5;
				display: block;
				content: attr(data-text);
				font-family: 'Noto Sans Japanese', sans-serif;
				font-size: 1.3rem;
				font-weight: $font-demilight;
			}
		}
	}
}

.ttl02 {
	margin: 0 0 20px;
	text-align: center;
	font-size: 3.4rem;
	font-weight: bold;
	@include noto-serif;
	line-height: 1.3;
	position: relative;
	padding-bottom: 20px;
	&__text {
		&::after {
			content: attr(data-text);
			font-family: 'Noto Sans Japanese', sans-serif;
			color: #969696;
			font-size: $s;
			font-weight: $font-demilight;
			display: block;
		}
	}
}

.ttl03 {
	margin: 0 0 50px;
	font-size: 2.8rem;
	text-align: center;
	line-height: 1.4;
	@include noto-serif;
	padding-bottom: 10px;
	position: relative;
	&::after {
		border-bottom: 1px solid #929292;
		@include centering-elements(true, false);
		content: '';
		bottom: 0;
		width: 80px;
	}
}

.ttl04 {
	position: relative;
	margin: 0 0 10px;
	font-size: 2.6rem;
	font-weight: bold;
	@include noto-serif;
	padding-left: 30px;
	&::before {
		border-top: 1px solid #929292;
		@include centering-elements(false, true);
		content: '';
		left: 0;
		width: 25px;
	}
}

.ttl05 {
	position: relative;
	margin: 0 0 20px;
	padding: 0 0 15px;
	border-bottom: 1px solid $borderColor;
	font-size: $xl;
	line-height: 1.4;
	
	&::after {
		position: absolute;
		left: 0;
		bottom: -1px;
		content: '';
		width: 115px;
		border-bottom: 1px solid $baseColor;
	}
}

.ttl06 {
	margin: 0 0 10px;
	padding-bottom: 5px;
	border-bottom: 1px solid #929292;
	font-size: $m;
	line-height: 1.4;
}


.catch01 {
	@include noto-serif;
	text-align: center;
	font-size: 2.9rem;
	margin-bottom: 15px;
	color: $baseColor;
	transform: rotate(0.05deg); 
	letter-spacing: -0.05em;
}

/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -5px;
	 margin-right: -5px;

	 &> * {
		 margin-left: 5px;
		 margin-right: 5px;
		 width: calc((100% - 21px) / 2 );
	 }
 }

.column3 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 61px) / 3);
	}
	
	&--wide {
		margin-left: -15px;
		margin-right: -15px;
		&> * {
			margin-left: 15px;
			margin-right: 15px;
			width: calc((100% - 91px) / 3);
		}
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 14.6%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px;
	box-sizing: border-box;
	background: $white;

	&--gray {
		background: #f9f9f9;
	}

	&--border-gray {
		border: 1px solid $borderColor;
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
	&--brackets {
		border: 1px solid $baseColorLight;
		position: relative;
		margin-bottom: 30px;
		padding: 0;
		
		&::before,
		&::after,
		&__inner::before,
		&__inner::after {
			content:'';
			width: 30px;
			height: 30px;
			position: absolute;
			display: inline-block;
		}

		&::before {
			border-left: solid 1px $baseColor;
			border-bottom: solid 1px $baseColor;
			bottom: -1px;
			left: -1px;
		}

		&::after{
			border-right: solid 1px $baseColor;
			border-bottom: solid 1px $baseColor;
			bottom: -1px;
			right: -1px;
		}
		
		&__inner{
			padding: 25px;
			position: relative;
			&::before {
				border-left: solid 1px $baseColor;
				border-top: solid 1px $baseColor;
				top: -1px;
				left: -1px;
			}

			&::after{
				border-right: solid 1px $baseColor;
				border-top: solid 1px $baseColor;
				top: -1px;
				right: -1px;
			}
		}
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 15px;
		background: $white;
		border-bottom: 1px solid $borderColor;
		border-top: 1px solid $borderColor;
		font-size: $s;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	.bg-bluelight {
		background: $baseColorLight;
		border-top: 1px solid $baseColorLight;
		border-bottom: 1px solid $white!important;
	}
	tr:last-child {
		.bg-bluelight {
			border-bottom: 1px solid $baseColorLight!important;
		}
	}
	.bg-gray {
		background: #f3f3f3;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		line-height: 1.4;
	}
}
.table--list {
	th,
	td {
		background: $white;
		border: none;
		padding: 10px 15px;
	}
	tr:nth-child(odd) {
		th,td {
			background: $baseColorLight;
		}
	}
}

/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	height: 50px;
	margin: 0 5px;
	padding: 0 30px;
	color: $white;
	font-size: $m;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	cursor: pointer;
	transition: 0.3s all;
	
	&:hover {
		opacity: 0.7;
		text-decoration: none;
	}
	
	&--effect {
		text-align: center;
		display: inline-block;
		position: relative;
		text-decoration: none;
		text-transform: capitalize;
		width: 150px;
		overflow: hidden;
		transition: all 0.3s linear 0s;
		&::before {
			font-family: 'icomoon';
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			height: 100%;
			width: 45px;
			background-color: rgba($white,0.3);
			transform: scale(0,1);
			transition: all 0.3s linear 0s;
		}
		&:hover {
			opacity: 1.0;
			&:before {
				transform: scale(1,1);
     			text-indent: 0;
			}
		}
		&--next {
			&::before {
				content: "\f061";
				left: 0;
				border-radius: 0 50% 50% 0;
				transform-origin: left center;
			}
			&:hover {
				text-indent: 45px;
			}
		}
		&--back {
			&::before {
				content: "\f060";
				right: 0;
				border-radius: 50% 0 0 50%;
				transform-origin: right center;
			}
			&:hover {
				text-indent: -45px;
			}
		}
	}
	


	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 15px; }
	.icon-fix-right { right: 15px; }
	
	&--blue {
		background: $baseColor;
	}

	&--black {
		background: $textColor;
	}
	&--lg {
		min-width: 300px;
		height: 70px;
		font-size: $m;
	}
	&--md {
		min-width: 300px;
		height: 60px;
		line-height: 60px;
	}
	&--sm {
		min-width: inherit;
		height: 40px;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			padding: 10px 15px;
			background: none;
			border: 1px solid $baseColor;
			color: $baseColor;
			text-decoration: none;
			@include radius(3);
			
			&.current,
			&:hover {
				transition: 0.3s all;
				background: $baseColor;
				border: 1px solid $baseColor;
				color: $white;
				opacity: 1;
				text-decoration: none;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColor;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 10px;
	padding: 2px 10px;
	background: $baseColor;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	font-size: $m;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	width: 100px;
	height: 25px;
	border: 1px solid #808080;
	color: #808080;
	font-size: $s;
	display: flex;
	justify-content: center;
	align-items: center;
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin: 0 0 60px;
	&__item {
		padding: 15px 0;
		border-bottom: 1px solid #e4e4e4;
		&__date {
			color: #959595;
			font-size: 1.4rem;
			margin: 0 15px 0 0;
		}
		img {
			max-width: 100%;
			height: auto;
		}
	}
}

/* --------------------------------------------------- */
/* lnav */
/* --------------------------------------------------- */
.lnav {
	display: flex;
	flex-wrap: wrap;
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: 90px;
	
	&--column3 {
		& > * {
			width: calc((100% - 31px) / 3);
		}
	}
	&--column4 {
		& > * {
			width: calc((100% - 41px) / 4);
			min-width: 130px;
			.icon-fix-right {
				right: 5px;
			}
		}
	}
	&__item {
		margin-left: 5px;
		margin-right: 5px;
		background: #8bb6d3;
		cursor: pointer;
		display: block;
		height: 60px;
		padding: 0;
		transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
		&:hover {
			background-color: transparent;
			text-shadow: ntwo;
			&::before {
				top: 0%;
				bottom: auto;
				height: 100%;
			}
		}
		&::before {
			display: block;
			position: absolute;
			left: 0px;
			bottom: 0px;
			height: 0px;
			width: 100%;
			z-index: -1;
			content: '';
			background: $baseColor;
			transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
		}
		&__text {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}
	}
}
