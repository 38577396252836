@charset "utf-8";
/* ============================================================ */
/* recruit */
/* ============================================================ */
.recruit {
	.timeline {
		&__item {
			overflow: hidden;
			margin: 0;
			position: relative;
			
			&:last-child > * {
				padding-bottom: 0;
			}
			
			&__body {
				margin-left: 5px;
				&__title {
					line-height: 1.0;
					@include noto-serif;
					font-size: $xl;
					display: flex;
					&__date {
						line-height: 1.0;
						@include noto-serif;
						color: #609dbf;
						font-size: 2.2rem;
						padding-right: 20px;
						position: relative;
						margin-right: 5px;
						margin-bottom: 10px;
						&::after {
							content: "";
							width: 15px;
							border-top: 1px solid $borderColor;
							right: 0;
							@include centering-elements(false, true);
						}
					}
				}
				
				&__text {
					padding-bottom: 15px;
				}
			}
			&__img {
				min-width: 100%;
				padding-bottom: 45px;
			}
		}
	}
}
