@charset "utf-8";
/* ============================================================ */
/* logo */
/* ============================================================ */
.mainImage {
	animation-name: slideimage;
	animation-duration: 1.5s;
	background: $white;
	width: 160px;
	height: 310px;
	margin-top: -30px;
	margin-left: -30px;
	z-index: 2;
	position: absolute;
	&__link {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		height: 100%;
	}
}

.mainImage img {
	width: 83px;
	padding: 7px 0;
}

