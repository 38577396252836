@charset "utf-8";
/* ============================================================ */
/* office */
/* ============================================================ */
.office {
	.message-bnr {
		margin-bottom: 25px;
		&__title {
			font-size: 2.8rem;
			@include noto-serif;
			color: $baseColor;
			position: relative;
			line-height: 1.4;
			margin: 0 0 15px;
			letter-spacing: -0.05em;
		}
		&__name {
			text-align: right;
			font-size: 2.4rem;
			@include noto-serif;
			line-height: 1.3;
		}
	}
	#googlemap {
		height: 260px;
	}
}
