@charset "utf-8";
$baseColor: #358fc1;
$baseColorLight: #dfedf2;
$textColor: #1a1a1a;
$borderColor: #9fbfce;

$white: #fff;
$black: #000;

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin noto-serif {
	font-family: "Noto Serif JP", serif;
	font-optical-sizing: auto;
	font-weight: 500;
	font-style: normal;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@keyframes slideimage {
	0% {
		opacity: 0;
    	transform: translateX(-1000px);
	}
	100% {
		opacity: 1;
   		transform: translateX(0);
	}
}

@keyframes title-fade {
	0% {
		opacity: 0;
    	transform: translateY(-500px);
	}
	100% {
		opacity: 1;
   		transform: translateY(0);
	}
}

@keyframes menu-bar01 {
	0% {
		transform: translateY(12px) rotate(45deg);
	}
	50% {
		transform: translateY(12px) rotate(0);
	}
	100% {
		transform: translateY(0) rotate(0);
	}
}
@keyframes menu-bar02 {
	0% {
		transform: translateY(-12px) rotate(-45deg);
	}
	50% {
		transform: translateY(-12px) rotate(0);
	}
	100% {
		transform: translateY(0) rotate(0);
	}
}
@keyframes active-menu-bar01 {
	0% {
		transform: translateY(0) rotate(0);
	}
	50% {
		transform: translateY(12px) rotate(0);
	}
	100% {
		transform: translateY(12px) rotate(45deg);
		background: $textColor;
	}
}
@keyframes active-menu-bar03 {
	0% {
		transform: translateY(0) rotate(0);
	}
	50% {
		transform: translateY(-12px) rotate(0);
	}
	100% {
		transform: translateY(-12px) rotate(-45deg);
		background: $textColor;
	}
}
@keyframes menu-bar01sp {
	0% {
		transform: translateY(8px) rotate(45deg);
	}
	50% {
		transform: translateY(8px) rotate(0);
	}
	100% {
		transform: translateY(0) rotate(0);
	}
}
@keyframes menu-bar02sp {
	0% {
		transform: translateY(-8px) rotate(-45deg);
	}
	50% {
		transform: translateY(-8px) rotate(0);
	}
	100% {
		transform: translateY(0) rotate(0);
	}
}
@keyframes active-menu-bar01sp {
	0% {
		transform: translateY(0) rotate(0);
	}
	50% {
		transform: translateY(8px) rotate(0);
	}
	100% {
		transform: translateY(8px) rotate(45deg);
		background: $white;
	}
}
@keyframes active-menu-bar03sp {
	0% {
		transform: translateY(0) rotate(0);
	}
	50% {
		transform: translateY(-8px) rotate(0);
	}
	100% {
		transform: translateY(-8px) rotate(-45deg);
		background: $white;
	}
}
/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;

/* font-weight
-------------------------------------------------- */
$font-thin: 100;
$font-light: 200;
$font-demilight: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-black: 900;

