@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;
}

@media screen and (min-width:  767.5px) and (max-width:1024px) {
	html {
		font-size: calc(100vw / 102.4);
	}
}

.wrap {
	font-family: 'Noto Sans Japanese', sans-serif;
	color: $textColor;
	line-height: 1.8;
	font-size: 1.5rem;
	font-weight: $font-regular;
	font-feature-settings : "palt";
	letter-spacing: 0.05em;
	background: #f6f7f8;
	margin: 30px;
	overflow: hidden;
}

.sp-view {
	display: none !important;
}

a {
	color: $baseColor;
	text-decoration: underline;
	
	&:hover {
		text-decoration: none;
	}
}


/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 0 0 50px;
	padding: 12px 0;
	border-bottom: 1px solid $borderColor;
	font-size: $xxs;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 20px;
	}
	&__item {
		
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 160px;
	bottom: 270px;
	z-index: 3;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		background: rgba($baseColor, 0.9);
		color: $white;
		font-size: $xxxl;
		text-decoration: none;
		@include radius(4);
		@include transition;
		
		&:hover {
			@include opacity;
		}
	}
}

/* --------------------------------------------------- */
/* contact-btn */
/* --------------------------------------------------- */
.contact-btn {
	position: fixed;
	right: 45px;
	bottom: 45px;
	z-index: 10;
	@media screen and (min-width:  767.5px) and (max-width:1024px) {
		right: 30px;
	}
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		margin: 5px;
		background: $textColor;
		color: $white;
		font-size: $xxxl;
		text-decoration: none;
		@include radius(25);
		@include transition;
		
		&:hover {
			font-size: 2.8rem;
			margin: 0px;
			background: $baseColor;
			width: 60px;
			height: 60px;
			@include radius(30);
		}
		
		.icon-page-top {
			padding-right: 4px;
			padding-top: 2px;
		}
	}
}