@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */

#index {
	height: 100%;
	margin: 0;
	
	.wrap {
		height: 100%;
		border: 30px solid $white;
		padding-bottom: 55px;
		margin: 0;
		background: linear-gradient(-45deg, rgba(223, 239, 247, 0.1), rgba(223, 239, 247, 0.1));
	}
	
	.visual {
		text-align: center;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		&__movie {
			position: fixed;
			right: 0;
			bottom: 0;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			z-index: -100;
			background-size: cover;
		}
		&__logo {
			position: relative;
			z-index: 2;
			justify-content: center;
			align-items: center;
			text-align:center;
			margin-right: 150px;
			.index-title {
				color: $white;
				font-size: 2.2rem;
				@include noto-serif;
				text-shadow: 0px 0px 11px #444444;
				display: block;
				
				&--01 {
					font-size: 3.8rem;
				}
			}
		}
	}

	.pagetop {
		display: none;
	}
	footer {
		position: fixed;
		bottom: 30px;
		background: none;
		.footer-info {
			&__body {
				padding: 0 190px 0 0;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 0 160px 0 0; }
			}
		}
	}
	.footer-news {
		position: absolute;
		left: 30px;
		bottom: 30px;
		&__item {
			line-height: 120%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			background: $white;
			max-width: 770px;
			padding: 15px 20px 0 0;
			@media screen and (min-width:  767.5px) and (max-width:1024px) {
				padding-right: 20px;
			}
            &__head {
                display: flex;
                align-items: center;
                height: 100%;
            }
			&__title {
				font-size: 1.6rem;
				color: $baseColor;
				padding-right: 20px;
				white-space: nowrap;
				@include noto-serif;
				@media screen and (min-width:  767.5px) and (max-width:1024px) {
					padding-right: 10px;
					margin-right: 10px;
				}
			}
            &__body {
                border-left: 1px solid #e4e4e4;
                padding-left: 15px;
            }
			&__text {
				display: flex;
				align-items: flex-start;
				text-align: left;
				justify-content: flex-start;
				overflow: hidden;
				width: 41em;
				color: $textColor;
				text-decoration: none;
				max-width: 450px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
                margin: 0 0 10px;
				padding-right: 5px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) {
					max-width: 315px;
				}
                &:last-child {
                    margin-bottom: 0;
                }
				a {
					color: $textColor;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					&:hover {
						text-decoration: underline;
						color: $baseColor;
					}
				}
			}
			&__list {
				background: $baseColor;
				color: $white;
				text-decoration: none;
				width: 30px;
				height: 30px;
				border-radius: 15px;
				font-size: 1.5rem;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid $baseColor;
				&:hover {
					background: $white;
					color: $baseColor;
				}
			}
		}
	}
	.footer-contact {
		display: none;
	}
}